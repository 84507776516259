/* For Amplify Authenticator */
:root {
  height: 100%;

  --primary-color: #5e7480;
  --primary-tint: #3c3c3c;
  --primary-shade: #3c3c3c;
  --secondary-color: #3c3c3c;

  --amplify-primary-color: var(--primary-color);
  --amplify-primary-tint: var(--primary-tint);
  --amplify-primary-shade: var(--primary-shade);
}

body {
  background-color: white !important;
  margin: 0;
  height: 100%;
}

@font-face {
  font-family: Mulish;
  font-weight: 400;
  src: url(./fonts/Mulish-VariableFont_wght.ttf);
}

@font-face {
  font-family: Mulish;
  font-weight: 600;
  src: url(./fonts/Mulish-VariableFont_wght.ttf);
}

@font-face {
  font-family: Mulish;
  font-weight: 700;
  src: url(./fonts/Mulish-VariableFont_wght.ttf);
}

@font-face {
  font-family: Fredoka;
  font-weight: 400;
  src: url(./fonts/Fredoka-VariableFont.ttf);
}

@font-face {
  font-family: Fredoka;
  font-weight: 500;
  src: url(./fonts/Fredoka-VariableFont.ttf);
}

@font-face {
  font-family: Fredoka;
  font-weight: 700;
  src: url(./fonts/Fredoka-VariableFont.ttf);
}

@font-face {
  font-family: Inter;
  font-weight: 500;
  src: url(./fonts/Inter-Regular.ttf);
}
